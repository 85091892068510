import React from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

interface ITelematicsResultPopupProps
{
	show: boolean;
	newNodes: number;
	newFiles: number;
	onClose: () => void;
}

/** This displays the results of a telematics scan */
export const TelematicsResultPopup = ({ show, newNodes, newFiles, onClose }: ITelematicsResultPopupProps) => 
	<Dialog open={show} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
		<DialogTitle id="alert-dialog-title">Scan Results</DialogTitle>
		<DialogContent>
			<DialogContentText id="alert-dialog-description">
				<Typography variant="subtitle1">Scan completed.</Typography>
				<Typography>
					<ul>
						<li>{newNodes} new nodes found.</li>
						<li>{newFiles} new files found.</li>
					</ul>
				</Typography>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose} color="primary" autoFocus>
					OK
			</Button>
		</DialogActions>
	</Dialog>;
