import React from 'react';
import { Paper, LinearProgress, Button } from '@material-ui/core';
import './UploadJob.scss';

export interface IUploadJobTileProps
{
	filename: string;
	uploading: boolean;
	uploadProgress: number;
	onFileRemoved: (filename: string)=>void;
}

export const UploadJobTile = ({ filename, uploading, uploadProgress, onFileRemoved }:IUploadJobTileProps): JSX.Element =>
{
	return <Paper className="FileRow">
		<span className="Filename" title={filename}>{filename}</span> <span className="FilePercent">{(uploading && uploadProgress)
			? `(${uploadProgress.toFixed()}%)`
			: ''}</span>
		<Button className="FileCancel" onClick={()=>onFileRemoved(filename)}>X</Button>
		{(uploading && uploadProgress)
			? <LinearProgress variant="determinate" value={uploadProgress} />
			: <></>}
	</Paper>;
};
