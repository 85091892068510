import { observable } from 'mobx';
import { IAgentDTO } from '../Models/IAgentDTO';
import { IMessageResponse } from '../Models/IMessageResponse';
import { IJobDTO } from '../Models/IJobDTO';
import { CancellationToken } from '../Threading/CancellationToken';
import { IScanStatusDTO } from '../Models/IScanStatusDTO';
import { ITelematicsInfoDTO } from '../Models/ITelematicsInfoDTO';
import { IClientConfig } from '../Models/IClientConfig';

/**
 * Data state
 * The general state of the data store
 */
export enum DataState
{
	/**
	 * The state has not been set to a valid value
	 */
	Unknown = 0,
	/**
	 * A blocking load is occuring
	 */
	Loading,
	/**
	 * A non-blocking load is occuring
	 */
	Updating,
	/**
	 * All loading is complete and the application can run
	 */
	Complete,
	/**
	 * An unhandled error occured and the application must stop
	 */
	DataError,
	/**
	 * The user does not have permission to the requested data 
	 */
	PermissionError,
}

export interface IFileUploadObject{
	request: XMLHttpRequest;
	file: File;
}

/**
 * Data store
 * This store holds all data that is business critical.  It is expected that this data will come from,
 * or be stored to, the user's account on the server.
 */
export class DataStore
{
	public config: IClientConfig | null = null;

	/**
	 * Gets the current session and stores it
	 * @returns session 
	 */
	public async GetSession(): Promise<IAgentDTO | null>
	{
		// Mark that this is a blocking load
		this.state = DataState.Loading;

		// Get the session.  This uses a cookie for authentication
		const message = await fetch('/api/1/session/current');

		// Check to see if the cookied did not validate
		if (message.status === 401)
		{
			this.state = DataState.PermissionError;
			return null;
		}

		// Parse the response; a session is really an agent
		const response = await message.json() as IMessageResponse<IAgentDTO>;
		if (response.Success !== true)
		{
			this.state = DataState.DataError;
			this.errorMessage = response.ErrorMessage;
			return null;
		}

		this.session = response.Data;

		// Indicate we are done loading
		this.state = DataState.Complete;
		return this.session;
	}

	public async GetTelematicsPortal(accountId: string, cancellationToken?:CancellationToken)
	{
		// Mark that this is a blocking load
		this.state = DataState.Updating;

		// Get the session.  This uses a cookie for authentication
		const message = await fetch(`/api/1/account/${accountId}/telematicsPortal`);

		// Check to see if the cookied did not validate
		if (message.status === 401)
		{
			this.state = DataState.PermissionError;
			return null;
		}

		if(cancellationToken?.cancelled)
		{
			return null;
		}

		// Parse the response
		const response = await message.json() as IMessageResponse<string>;
		if (response.Success !== true || response.Data === null)
		{
			this.state = DataState.DataError;
			this.errorMessage = response.ErrorMessage;
			return null;
		}

		if(cancellationToken?.cancelled)
		{
			return null;
		}

		let portalUrl = new URL(response.Data);
		// ensure we are using https for the portal url
		portalUrl.protocol = 'https';


		// Indicate we are done loading
		this.state = DataState.Complete;
		return portalUrl.href;
	}


	public async GetJobs(accountId: string, includeCompleted: boolean, includeCancelled: boolean, cancellationToken?:CancellationToken)
	{
		// update the info panel immediately so there is some user feedback
		await this.UpdateTelematicsInfo(accountId, cancellationToken);
		
		if(cancellationToken?.cancelled)
		{
			return null;
		}
		
		// Mark that this is a blocking load
		this.state = DataState.Updating;

		// This will need to loop until no additional jobs are retreived.
		let newJobList : IJobDTO[] = [];
		let currentFetchPage = 0;
		let fetchComplete = false;
		while(!fetchComplete)
		{

			// Get the session.  This uses a cookie for authentication
			const message = await fetch(`/api/1/account/${accountId}/job?include=[Id,RcisUserToken,FileId,Created,CurrentStatus,LastUpdated,CurrentOnsiteJobId,File,CurrentMessage]&includeCompleted=${includeCompleted}&includeCancelled=${includeCancelled}&start=${currentFetchPage}`);

			// Check to see if the cookied did not validate
			if (message.status == 401)
			{
				this.state = DataState.PermissionError;
				return null;
			}

			if(cancellationToken?.cancelled)
			{
				return null;
			}

			// Parse the response
			const response = await message.json() as IMessageResponse<IJobDTO[]>;
			if (response.Success !== true || response.Data === null)
			{
				this.state = DataState.DataError;
				this.errorMessage = response.ErrorMessage;
				return null;
			}

			if(cancellationToken?.cancelled)
			{
				return null;
			}

			// Devin 1/5/2021
			// fetching all jobs can result in some serious lag, and eventually a total freeze of the front end.
			// this needs to be done differently if we are going to loop through and grab pages of data like this.

			// if our fetch results in an empty list, we have reached the end of the list
			// otherwise just add the fetched jobs to the list.
			if(response.Data.length > 0)
			{
				newJobList = newJobList.concat(response.Data);
				// currentFetchPage = currentFetchPage+100;
			}
			// else
			// {
			// 	fetchComplete = true;
			// }

			// to improve performance for the time being, we are just grabbing the first page of jobs
			fetchComplete = true;
		}
		this.jobs = newJobList;


		// Indicate we are done loading
		this.state = DataState.Complete;
		return this.jobs;
	}

	
	@observable
	public telematicsNodeCount: number = 0;

	@observable
	public telematicsImportedFileCount: number = 0;

	@observable
	public telematicsPendingFileCount: number = 0;

	@observable
	public telematicsProviderNodeCount: number = 0;

	public async UpdateTelematicsInfo(accountId: string, cancellationToken?:CancellationToken)
	{
		// Mark that this is a blocking load
		//this.state = DataState.Updating;

		// Get the session.  This uses a cookie for authentication
		const message = await fetch(`/api/1/account/${accountId}/telematicsInfo`);

		// Check to see if the cookied did not validate
		if (message.status == 401)
		{
			this.state = DataState.PermissionError;
			return null;
		}

		if(cancellationToken?.cancelled)
		{
			return null;
		}

		// Parse the response
		const response = await message.json() as IMessageResponse<ITelematicsInfoDTO>;
		if (response.Success !== true || response.Data == null)
		{
			this.state = DataState.DataError;
			this.errorMessage = response.ErrorMessage;
			return null;
		}

		if(cancellationToken?.cancelled)
		{
			return null;
		}
		this.telematicsNodeCount = response.Data.ConnectedNodesCount;
		this.telematicsImportedFileCount = response.Data.ImportedFilesCount;
		this.telematicsProviderNodeCount = response.Data.ConnectedProvidersCount;
		this.telematicsPendingFileCount = response.Data.PendingFilesCount;


		// Indicate we are done loading
		//this.state = DataState.Complete;
	}

	public ClearJobs()
	{
		this.jobs = [];
	}

	public async UpdateJobState(jobId: string)
	{
		// Mark that this is a blocking load
		this.state = DataState.Updating;

		// Get the session.  This uses a cookie for authentication
		const message = await fetch(`/api/1/job/${jobId}/status`,
			{
				method: 'POST'
			});

		// Check to see if the cookied did not validate
		if (message.status == 401)
		{
			this.state = DataState.PermissionError;
			return null;
		}

		// Parse the response; a session is really an agent
		const response = await message.json() as IMessageResponse<IJobDTO>;
		if (response.Success !== true || response.Data == null)
		{
			this.state = DataState.DataError;
			this.errorMessage = response.ErrorMessage;
			return null;
		}

		// Replace the updated data of this once job
		const indexOfPriorJob = this.jobs.findIndex(j => j.Id === jobId);
		if(indexOfPriorJob < 0)
		{
			return this.jobs;
		}

		const replacementJobs = this.jobs.slice();
		replacementJobs[indexOfPriorJob] = response.Data;
		this.jobs = replacementJobs;

		// Indicate we are done loading
		this.state = DataState.Complete;
		return this.jobs;
	}

	/** 
	 * The current session (agent)
	 */
	@observable
	public session: IAgentDTO | null = null;

	@observable
	public jobs: Array<IJobDTO> = observable([]);

	/**
	 * The current state of the data
	 */
	@observable
	public state: DataState = DataState.Unknown;

	/**
	 * Any custom error message (should only be set if state == DataState.DataError)
	 */
	@observable
	public errorMessage: string | null = null;

	@observable
	public fileUploads: IFileUploadObject[] = [];

	public removeFileUploadRequest(req: XMLHttpRequest)
	{
		const reqIdx = this.fileUploads.findIndex(upload => upload.request === req);
		if(reqIdx > -1)
		{
			return this.fileUploads.splice(reqIdx,1);
		}
		return [];
	}

	public cancelAllFileUploads()
	{
		this.fileUploads.slice().forEach(upload => upload.request.abort());
	}

	public cancelFileUpload(filename: string)
	{
		const upload = this.fileUploads.find(u => u.file.name === filename);
		if(upload)
		{
			upload.request.abort();
		}
	}

	public UploadFile(
		customerId: string,
		file: File,
		onChange: (filename: string, percentage: number) => void,
		onComplete: (filename: string) => void,
		onAbort: (filename: string) => void,
		onError: (filename: string) => void)
	{
		return new Promise((resolve, reject) =>
		{
			const agent = this.session;
			if(!agent)
			{
				reject('No agent selected');
				return;
			}

			const customer = agent.Accounts.find(c => c.Id == customerId) || null;
			if (customer == null)
			{
				reject('No customer selected');
				return;
			}

			const req = new XMLHttpRequest();

			// add this request to our list of pending uploads
			this.fileUploads.push({request: req, file: file});

			req.upload.addEventListener('progress', event =>
			{
				if (event.lengthComputable)
				{
					onChange(file.name, (event.loaded / event.total) * 100);
				}
			});

			req.addEventListener('load', event =>
			{
				onComplete(file.name);
				resolve(req.response);
				// remove the inactive request from our upload list
				this.removeFileUploadRequest(req);
			});

			req.addEventListener('error', event =>
			{
				onError(file.name);
				reject(req.response);
				// remove the inactive request from our upload list
				this.removeFileUploadRequest(req);
			});

			req.addEventListener('abort', event =>
			{
				onAbort(file.name);
				reject(req.response);
				// remove the inactive request from our upload list
				this.removeFileUploadRequest(req);
			});

			const formData = new FormData();
			formData.append('file', file, file.name);
			req.open('PUT', `/api/1/agent/${agent.Id}/account/${customer.Id}/job`);
			req.send(formData);
		});
	}

	public async CancelJob(jobId: string)
	{
		// Mark that this is a blocking load
		this.state = DataState.Loading;

		// Get the session.  This uses a cookie for authentication
		const message = await fetch(`/api/1/job/${jobId}/cancel`,
			{
				method: 'POST'
			});

		// Check to see if the cookied did not validate
		if (message.status == 401)
		{
			this.state = DataState.PermissionError;
			return null;
		}

		// Parse the response
		const response = await message.json() as IMessageResponse<IJobDTO>;
		if (response.Success !== true || response.Data == null)
		{
			this.state = DataState.DataError;
			this.errorMessage = response.ErrorMessage;
			return null;
		}

		// Replace the updated data of this once job
		const indexOfPriorJob = this.jobs.findIndex(j => j.Id === jobId);
		const replacementJobs = this.jobs.slice();
		replacementJobs[indexOfPriorJob] = response.Data;
		this.jobs = replacementJobs;

		// Indicate we are done loading
		this.state = DataState.Complete;
		return this.jobs;
	}

	public async RetryJob(jobId: string)
	{
		// Mark that this is a blocking load
		this.state = DataState.Loading;

		// Get the session.  This uses a cookie for authentication
		const message = await fetch(`/api/1/job/${jobId}/retry`,
			{
				method: 'POST'
			});

		// Check to see if the cookied did not validate
		if (message.status == 401)
		{
			this.state = DataState.PermissionError;
			return null;
		}

		// Parse the response
		const response = await message.json() as IMessageResponse<IJobDTO>;
		if (response.Success !== true || response.Data == null)
		{
			this.state = DataState.DataError;
			this.errorMessage = response.ErrorMessage;
			return null;
		}

		// Replace the updated data of this once job
		const indexOfPriorJob = this.jobs.findIndex(j => j.Id === jobId);
		const replacementJobs = this.jobs.slice();
		replacementJobs[indexOfPriorJob] = response.Data;
		this.jobs = replacementJobs;

		// Indicate we are done loading
		this.state = DataState.Complete;
		return this.jobs;
	}

	public async ReportError(jobId:string)
	{
		// Mark that this is a blocking load
		this.state = DataState.Loading;

		// Get the session.  This uses a cookie for authentication
		const message = await fetch(`/api/1/agent/${this.session?.Id}/job/${jobId}/error`,
			{
				method: 'POST'
			});

		// Check to see if the cookied did not validate
		if (message.status == 401)
		{
			this.state = DataState.PermissionError;
			return null;
		}

		const response = await message.json() as IMessageResponse<string>;
		if (response.Success !== true || response.Data == null)
		{
			this.state = DataState.DataError;
			this.errorMessage = response.ErrorMessage;
			return null;
		}

		window.open(response.Data);

		// Indicate we are done loading
		this.state = DataState.Complete;
		return;
	}

	public async ScanRemote(accountId:string)
	{
		// Mark that this is a blocking load
		this.state = DataState.Loading;

		// This uses a cookie for authentication
		const message = await fetch(`/api/1/account/${accountId}/telematics/scan`,
			{
				method: 'POST'
			});

		// Indicate we are done loading
		this.state = DataState.Complete;
		return;
	}

	
	public async QueryScanStatus(accountId:string) : Promise<IScanStatusDTO | null>
	{
		// This uses a cookie for authentication
		const message = await fetch(`/api/1/account/${accountId}/telematics/scan`,
			{
				method: 'GET'
			});

		const response = await message.json() as IMessageResponse<IScanStatusDTO>;
		if(!response.Success)
		{
			this.state = DataState.DataError;
			this.errorMessage = response.ErrorMessage;
			return null;
		}
		return response.Data;
	}


	/**
	 * Send an invitation to the specified email that will allow the recipient to connect to the specified account
	 * @param email The email address to dispatch the invitation to
	 * @param accountId The account under the current agent that the invitation will connect to
	 */
	public async SendInvitation(email:string, accountId:string)
	{
		// Mark that this is a blocking load
		this.state = DataState.Loading;

		// Get the session.  This uses a cookie for authentication
		const message = await fetch(`/api/1/account/${accountId}/invitation`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(email),
			});

		// Check to see if the cookied did not validate
		if (message.status == 401)
		{
			this.state = DataState.PermissionError;
			return null;
		}

		const response = await message.json() as IMessageResponse<string>;
		if (response.Success !== true || response.Data == null)
		{
			this.state = DataState.DataError;
			this.errorMessage = response.ErrorMessage;
			return null;
		}

		// Indicate we are done loading
		this.state = DataState.Complete;
		return;

	}
}
