import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, withStyles, Theme, createStyles } from '@material-ui/core';
import { IStandardAppProps } from '../../Models/IStandardAppProps';
import { JL } from 'jsnlog';
import dayjs from 'dayjs';
import './JobTable.scss';
import { JobSortMethod, ApplicationState } from '../../Stores/ApplicationState';
import { observer } from 'mobx-react';
import { KeyboardArrowDown } from '@material-ui/icons';
import { IJobDTO } from '../../Models/IJobDTO';

const StyledTableCell = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
		body: {
			fontSize: 14,
		},
	}),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
	createStyles({
		selected: {
			backgroundColor: theme.palette.secondary.light + ' !important',
			color: theme.palette.primary.dark,
		}
	}),
)(TableRow);

const StyledTableSortLabel = withStyles((theme: Theme) =>
	createStyles({
		icon: {
			color: theme.palette.primary.contrastText + ' !important',
		}
	}),
)(TableSortLabel);

/** 
 * Parse an object key, which is of the form {userId}/.../{filename}
 */
const filenameFromObjectKey = (key: string) =>
{
	const parts = key?.split('/');

	// Validate this key is in the correct form
	if (key == null || parts.length < 2)
	{
		JL().error(`Unrecognized object key ${key}`);
		return 'Error getting filename';
	}

	// Return the last part
	return parts[parts.length - 1];
};

const ageFromDate = (date: string) =>
{
	const difference = dayjs(new Date().toUTCString()).diff(dayjs(date), 'hour');
	if (difference > 48)
	{
		return dayjs().diff(dayjs(date), 'day') + ' days';
	}

	if (difference > 24)
	{
		return (difference / 24).toFixed(2) + ' days';
	}

	if (difference < 2)
	{
		return dayjs().diff(dayjs(date), 'minute') + ' minutes';
	}

	return difference + ' hours';
};

const CreateSortUpdateMethod = (applicationState: ApplicationState, method: JobSortMethod) =>
{
	return () =>
	{
		if(applicationState.jobSortMethod === method)
		{
			applicationState.jobSortAscending = !applicationState.jobSortAscending;
			return;
		}

		applicationState.jobSortAscending = true;
		applicationState.jobSortMethod = method;
	};
};

const formatStatus = (job:IJobDTO) =>
{
	if(job.CurrentStatus !== 'Error')
	{
		return job.CurrentStatus;
	}

	return `${job.CurrentStatus}:${job.CurrentMessage}`;
};

export const JobTable = observer((props:IStandardAppProps) =>
{
	const sortByName = CreateSortUpdateMethod(props.applicationState, JobSortMethod.ByName);
	const sortByUpdated =  CreateSortUpdateMethod(props.applicationState, JobSortMethod.ByUpdated);
	const sortByCreated =  CreateSortUpdateMethod(props.applicationState, JobSortMethod.ByCreated);
	const sortByStatus =  CreateSortUpdateMethod(props.applicationState, JobSortMethod.ByStatus);

	return (
		<TableContainer className="JobTable">
			<Table size="small" aria-label="a dense table">
				<TableHead>
					<TableRow>
						<StyledTableCell onClick={sortByName}>
							Filename
							<StyledTableSortLabel
								IconComponent={KeyboardArrowDown}
								active={props.applicationState.jobSortMethod === JobSortMethod.ByName}
								direction={props.applicationState.jobSortAscending ? 'asc' : 'desc'}
								onClick={sortByName}/>
						</StyledTableCell>
						<StyledTableCell onClick={sortByUpdated}>
							Last Updated
							<StyledTableSortLabel
								IconComponent={KeyboardArrowDown}
								active={props.applicationState.jobSortMethod === JobSortMethod.ByUpdated}
								direction={props.applicationState.jobSortAscending ? 'asc' : 'desc'}
								onClick={sortByUpdated}/>
						</StyledTableCell>
						<StyledTableCell onClick={sortByCreated}>
							Age
							<StyledTableSortLabel
								IconComponent={KeyboardArrowDown}
								active={props.applicationState.jobSortMethod === JobSortMethod.ByCreated}
								direction={props.applicationState.jobSortAscending ? 'asc' : 'desc'}
								onClick={sortByCreated}/>
						</StyledTableCell>
						<StyledTableCell onClick={sortByStatus}>
							Status
							<StyledTableSortLabel
								IconComponent={KeyboardArrowDown}
								active={props.applicationState.jobSortMethod === JobSortMethod.ByStatus}
								direction={props.applicationState.jobSortAscending ? 'asc' : 'desc'}
								onClick={sortByStatus}/>
						</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{
						props.applicationState.sortedJobs.map(j => (
							<StyledTableRow
								key={j.Id}
								selected={j.Id === props.applicationState.selectedJob?.Id}
								onClick={() => props.applicationState.selectJob(j.Id)}
							>
								<TableCell title={j.CurrentOnsiteJobId}>{filenameFromObjectKey(j.File.ObjectKey)}</TableCell>
								<TableCell title={j.LastUpdated}>{ageFromDate(j.LastUpdated)} ago</TableCell>
								<TableCell title={j.Created}>{ageFromDate(j.Created)}</TableCell>
								<TableCell >{formatStatus(j)}</TableCell>
							</StyledTableRow>
						))
					}
				</TableBody>
			</Table>
		</TableContainer>
	);
});