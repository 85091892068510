import React, { useState, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions } from '@material-ui/core';
export const InvitationDialog = (props: {
	open: boolean;
	handleClose: () => void;
	handleInvite: (email: string) => void;
}) =>
{
	const [email, setEmail] = useState('');
	const setEmailFromUpdate = useCallback((evt) => setEmail(evt.target.value), []);
	const sendInvite = useCallback(() => props.handleInvite(email), [email, props.handleInvite]);
	return <Dialog open={props.open} onClose={props.handleClose}>
		<DialogTitle id="form-dialog-title">Invite to connect</DialogTitle>
		<DialogContent>
			<DialogContentText>
				To invite someone to share their precision ag data with you,
				please enter their email address here.<br/>
				<br/>
				The recipient will be able to enter their credentials for any AGI supported telematics system 
				they use for data import, identical to the Manage Equipment button.
			</DialogContentText>
			<TextField autoFocus margin="dense" id="name" label="Email Address" type="email" fullWidth onChange={setEmailFromUpdate} />
		</DialogContent>
		<DialogActions>
			<Button onClick={props.handleClose} color="primary">
				Cancel
			</Button>
			<Button onClick={sendInvite} variant="contained" color="primary">
				Send Email
			</Button>
		</DialogActions>
	</Dialog>;
};
