import React from 'react';
import './App.scss';
import { DataState } from '../Stores/DataStore';
import { ClipLoader } from 'halogenium';
import { observer } from 'mobx-react';
import { IStandardAppProps } from '../Models/IStandardAppProps';
import { Content } from './Content';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#04649D'
		},
		secondary:{
			main: '#8dc63f'
		}
	}
});

/**
 * The basic Application component.
 * This component handles the initial loading display and any errors
 */
const App = observer((props: IStandardAppProps) =>
{
	// Handle the initial blocking load
	if (props.dataStore === null || props.dataStore.state === DataState.Unknown || props.dataStore.state == DataState.Loading)
	{
		return (
			<div className="App">
				<div className="Holder">
					<ClipLoader color='#0000FF' />
				</div>
			</div>
		);
	}

	// Handle unknown errors
	if (props.dataStore.state === DataState.DataError)
	{
		return (
			<div className="App">
				<div className="Holder">
					<h5>{props.dataStore.errorMessage}</h5>
				</div>
			</div>
		);
	}

	// Handle authentication failure
	if (props.dataStore.state === DataState.PermissionError)
	{
		return (
			<div className="App">
				<div className="Holder">
					<h5>Your session has expired.  Please launch the session again.</h5>
				</div>
			</div>
		);
	}

	// Render the app main content
	return (
		<div className="App">
			<ThemeProvider theme={theme}>
				<Content dataStore={props.dataStore} applicationState={props.applicationState} />
			</ThemeProvider>
		</div>
	);
});

export default App;


