import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Page/App';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { JL } from 'jsnlog';
import { ApplicationState } from './Stores/ApplicationState';
import { IMessageResponse } from './Models/IMessageResponse';
import { IClientConfig } from './Models/IClientConfig';
import { DataStore } from './Stores/DataStore';
import { MockLauncher } from './Page/Mock';

const dataStore = new DataStore();
const applicationState = new ApplicationState(dataStore);

// Documentation for configuring JSNLog and enabling "send to browser console"
// http://jsnlog.com/Documentation/JSNLogJs/JL/SetOptions
// http://jsnlog.com/Documentation/JSNLogJs/ConsoleAppender
// tslint:disable-next-line: variable-name
const __jsnlog_configure = (jl: JL.JSNLog) => 
{
	// Debugging: all loggers log to both the server and the console
	const ajaxAppender = jl.createAjaxAppender('ajaxAppender');
	const consoleAppender = jl.createConsoleAppender('consoleAppender');
	jl().setOptions({appenders: [ajaxAppender, consoleAppender]});
};
__jsnlog_configure(JL);
JL().info('JSNLog enabled');


async function run(): Promise<void>
{
	try
	{
		const response = await fetch('/.well-known/client-configuration');
		if(response.status != 200)
		{
			throw response.statusText;
		}

		dataStore.config = (await response.json() as IMessageResponse<IClientConfig>)?.Data;

		// handle our mock page here
		if(document.location.href.endsWith('mock'))
		{
			ReactDOM.render(<MockLauncher dataStore={dataStore} />, document.getElementById('root'));
			return;
		}


		// Application Insights for tracking usage and metrics
		if(dataStore.config?.ApplicationInsights)
		{
			JL().error('Enabling AI with '+dataStore.config.ApplicationInsights);
			const appInsights = new ApplicationInsights({ config: {
				instrumentationKey: dataStore.config.ApplicationInsights,
				/* ...Other Configuration Options... */
				enableAutoRouteTracking: true,
			} });
			appInsights.loadAppInsights();
			appInsights.trackPageView();
			appInsights.flush();
		}

		ReactDOM.render(<App dataStore={dataStore} applicationState={applicationState} />, document.getElementById('root'));

		await dataStore.GetSession();
	}
	catch(err)
	{
		if (err instanceof Error)
		{
			ReactDOM.render(<div>Error: {err.toString()}</div>, document.getElementById('root'));
		}
	}
}

run();

