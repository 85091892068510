import React, { useState } from 'react';
import { observer, Observer } from 'mobx-react';
import { Paper, Button, Checkbox, FormControlLabel, Typography, CircularProgress } from '@material-ui/core';
import { IStandardAppProps } from '../../Models/IStandardAppProps';
import { UploadJob } from './Upload/UploadJob';
import './JobsCard.scss';
import { JobTable } from './JobTable';
import { DataState } from '../../Stores/DataStore';

interface IJobsCardState
{
	includeCompletedJobs: boolean;
	includeCancelledJobs: boolean;
	open: boolean;

}

/** 
 * This renders the section of the page dedicated to displaying and manipulating job statuses.
 */
export class JobsCard extends React.PureComponent<IStandardAppProps, IJobsCardState>
{
	public constructor(props: IStandardAppProps)
	{
		super(props);
		this.state = {
			includeCompletedJobs: false,
			includeCancelledJobs: false,
			open: false
		};
	}

	private handleClose = () =>
	{
		this.setState({ open: false });
		if (this.props.applicationState.selectedCustomer)
		{
			this.props.dataStore.GetJobs(
				this.props.applicationState.selectedCustomer.Id,
				this.props.applicationState.includeCompletedJobs,
				this.props.applicationState.includeCancelledJobs);
		}
	};

	private handleOpen = () => this.setState({ open: true });

	private toggleIncludeCompleted = () =>
	{
		this.props.applicationState.includeCompletedJobs = !this.props.applicationState.includeCompletedJobs;
		if (this.props.applicationState.selectedCustomer)
		{
			this.props.dataStore.GetJobs(
				this.props.applicationState.selectedCustomer.Id,
				this.props.applicationState.includeCompletedJobs,
				this.props.applicationState.includeCancelledJobs);
		}
	}

	private toggleIncludeCancelled = () =>
	{
		this.props.applicationState.includeCancelledJobs = !this.props.applicationState.includeCancelledJobs;
		if (this.props.applicationState.selectedCustomer)
		{
			this.props.dataStore.GetJobs(
				this.props.applicationState.selectedCustomer.Id,
				this.props.applicationState.includeCompletedJobs,
				this.props.applicationState.includeCancelledJobs);
		}
	}

	public render()
	{
		return <div>
			<Typography variant="h6">Conversion Jobs</Typography>
			<Typography>The following are Precision Ag Translation jobs for the selected account.</Typography>
			<Observer>{() =>
				<Paper className="JobContainer">
					<div className="JobTableContainer">
						<JobTable applicationState={this.props.applicationState} dataStore={this.props.dataStore} />
						<Button variant="outlined" color="primary" onClick={this.handleOpen}>
							Upload Job
						</Button>
					</div>
					<div className="JobCommands">
						<FormControlLabel control={<Observer>{() => <Checkbox
							checked={this.props.applicationState.includeCompletedJobs}
							onClick={this.toggleIncludeCompleted}
							value="showCompleted"
							color="primary" />}</Observer>}
						label="Show completed jobs"
						title="Completed jobs will only displayed for an hour unless this is checked" />

						<FormControlLabel control={<Observer>{() => <Checkbox
							checked={this.props.applicationState.includeCancelledJobs}
							onClick={this.toggleIncludeCancelled}
							value="showCancelled"
							color="primary" />}</Observer>}
						label="Show cancelled jobs"
						title="Cancelled jobs will only displayed for an hour unless this is checked" />
						{
							<Button variant="outlined" disabled={this.props.applicationState.selectedJob == null}
								onClick={async () =>
								{
									if (this.props.applicationState.selectedJob)
									{
										await this.props.dataStore.UpdateJobState(this.props.applicationState.selectedJob.Id);
										if (!this.props.applicationState.selectedCustomer)
										{
											console.log('Bailing out as session went away');
											return;
										}
										await this.props.dataStore.GetJobs(
											this.props.applicationState.selectedCustomer.Id,
											this.props.applicationState.includeCompletedJobs,
											this.props.applicationState.includeCancelledJobs);
									}
								}}
							>
								Refresh Status
							</Button>}
						<Button
							variant="outlined"
							disabled={this.props.applicationState.selectedJob == null}
							title='Report an error with a specific job'
							onClick={() =>
							{
								if (this.props.applicationState.selectedJob)
								{
									this.props.dataStore.ReportError(this.props.applicationState.selectedJob.Id);
								}
							}}
						>
							Report Error
						</Button>
						<Button variant="outlined"
							disabled={this.props.applicationState.selectedJob == null}
							title='Resubmit the selected job to be rerun'
							onClick={() =>
							{
								if (this.props.applicationState.selectedJob)
								{
									this.props.dataStore.RetryJob(this.props.applicationState.selectedJob.Id);
								}
							}
							}
						>
							Retry
						</Button>
						<Button variant="outlined" disabled={this.props.applicationState.selectedJob == null}
							title='Stop the processing of this job'
							onClick={() =>
							{
								if (this.props.applicationState.selectedJob)
								{
									this.props.dataStore.CancelJob(this.props.applicationState.selectedJob.Id);
								}
							}
							}
						>
							Cancel
						</Button>
					</div>
					{
						this.props.dataStore.state == DataState.Updating
							? (
								<div className="UpdatingProgressContainer">
									<CircularProgress />
								</div>
							)
							: <></>
					}
				</Paper>}
			</Observer>
			<UploadJob open={this.state.open} handleClose={this.handleClose} applicationState={this.props.applicationState} dataStore={this.props.dataStore} />
		</div>;
	}
}
