
import React from 'react';
import './App.scss';
import { observer } from 'mobx-react';
import { Select, MenuItem, InputLabel, FormControl, Typography, Button } from '@material-ui/core';
import { IAccountDTO } from '../Models/IAccountDTO';
import { JobsCard } from './Jobs/JobsCard';
import { IStandardAppProps } from '../Models/IStandardAppProps';
import { TelematicsCard } from './Telematics/TelematicsCard';

/**
 * This renders the main content for the portal
 */
export const Content = observer((props: IStandardAppProps) =>
{
	return (
		<div className="Content">
			<div className='PageBanner' style={{display:'flex',flexDirection:'row'}}>
				<img src='/logo192.png' style={{maxWidth:48}}/>
				{/** Headline with the agent's name */}
				<Typography variant="h5" style={{padding:8}}>Agent Portal {
					(props.dataStore.session && props.dataStore.session.Name)
						?(': ' + props.dataStore.session?.Name)
						: ''}</Typography>
			</div>

			{/** Present a drop-down list of accounts the user can choose */}
			<Typography>Choose the account you would like to manage:</Typography>
			<FormControl>
				<InputLabel>Customer</InputLabel>
				<Select className="CustomerSelect"
					placeholder="Choose Customer"
					label="Customer"
					value={props.applicationState.selectedCustomer?.Id || ''}
					onChange={(e): IAccountDTO | null => props.applicationState.selectCustomer(e.target.value as string)}
				>
					{
						/** Map all customers in alphabetical order */
						props.dataStore.session?.Accounts.slice().sort(
							(a, b) => a.Name.toLocaleLowerCase().localeCompare(b.Name.toLocaleLowerCase())
						).map(c => <MenuItem value={c.Id} key={c.Id}>{c.Name}</MenuItem>)
					}
				</Select>
			</FormControl>
			{
				/** If a customer is selected, render the jobs card */
				/** If a customer is selected, render the telematics card */
				props.applicationState.selectedCustomer == null
					? <></>
					: <div>
						<TelematicsCard dataStore={props.dataStore} applicationState={props.applicationState} />
						<br/>
						<JobsCard dataStore={props.dataStore} applicationState={props.applicationState} />
					</div>
			}
		</div>
	);
});