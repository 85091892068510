import { IStandardAppProps } from '../../Models/IStandardAppProps';
import React from 'react';
import { Typography, Button, Grid, Dialog, DialogActions, DialogContentText, DialogContent } from '@material-ui/core';
import Iframe from 'react-iframe';
import './TelematicsCard.scss';
import { Observer } from 'mobx-react';
import { TelematicsScanButton } from './TelematicsScanButton';
import { TelematicsResultPopup } from './TelematicsResultPopup';
import { InvitationDialog } from './InvitationDialog';

interface IState
{
	manageDialogOpen: boolean;
	inviteDialogOpen: boolean;
	portalUrl: string;
	delayWarningDialogOpen: boolean;
}

/** 
 * This renders the section of the page dedicated to displaying and manipulating job statuses.
 */
export class TelematicsCard extends React.PureComponent<IStandardAppProps, IState>
{
	/* We will re-enable iframe once the page works in an iframe */
	private readonly shouldOpenManageEquipmentInNewTab = true;

	public constructor(props: IStandardAppProps)
	{
		super(props);
		this.state = {
			manageDialogOpen: false,
			inviteDialogOpen: false,
			delayWarningDialogOpen: false,
			portalUrl: ''
		};
	}

	public componentWillUnmount()
	{
		this.props.applicationState.StopTelematicsScanPolling();
	}

	private handleOpen = () =>
	{
		if (this.props.applicationState.selectedCustomer)
		{
			this.props.dataStore.GetTelematicsPortal(this.props.applicationState.selectedCustomer.Id).then(url =>
			{
				if (url)
				{
					this.setState({ portalUrl: url, manageDialogOpen: true, delayWarningDialogOpen: true });
					// If we aren't using iframes, open this in a new tab
					if(this.shouldOpenManageEquipmentInNewTab)
					{
						window.open(this.state.portalUrl, '_blank');
					}
				}
			});
		}
	};

	private handleClose = () => 
	{
		this.setState({ manageDialogOpen: false, inviteDialogOpen: false, delayWarningDialogOpen: false });
		this.handleScan();
	}

	private handleInvitation = () =>
	{
		// Display a popup requesting an email address for the grower
		this.setState({inviteDialogOpen:true});
	}

	private handleScan = () =>
	{
		this.props.applicationState.InitiateScan();
	}
	private sendInvite = (email:string) =>
	{
		// dispatch this invite for the currently selected grower to the provided email address
		this.props.dataStore.SendInvitation(email, this.props.applicationState.selectedCustomer!.Id);
	}

	public render()
	{

		return <Observer>{() =>
		{
			const isScanning = this.props.applicationState.isCurrentlyScanning;

			return (
				<Grid container item direction='column' xs={6}>
					<Grid item>
						<Typography variant="h6">Telematics</Typography>
					</Grid>
					<Grid container item direction='row'>
						<Grid container item direction='column' xs={3}>
							<Grid item>
								<Typography>{this.props.dataStore.telematicsProviderNodeCount} providers connected</Typography>
							</Grid>
							<Grid item>
								<Typography>{this.props.dataStore.telematicsNodeCount} total connected nodes</Typography>
							</Grid>
						</Grid>
						<Grid container item direction='column' xs={3}>
							<Grid item>
								<Typography>{this.props.dataStore.telematicsImportedFileCount} files retrieved</Typography>
							</Grid>
							<Grid item>
								<Typography>{this.props.dataStore.telematicsPendingFileCount} files pending</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid container item direction='row'>
						<Grid item>
							<Button variant="outlined" color="primary" onClick={this.handleOpen}>Manage Equipment</Button>
							<Dialog open={this.state.delayWarningDialogOpen}
								onClose={this.handleClose}
							>
								<DialogContent>
									<DialogContentText>After adding a new provider, files can take up to two hours to be available.</DialogContentText>
								</DialogContent>
								<DialogActions>
									<Button color="primary" autoFocus onClick={this.handleClose}>OK</Button>
								</DialogActions>
							</Dialog>
							<Dialog
								open={this.state.manageDialogOpen && !this.shouldOpenManageEquipmentInNewTab}
								onClose={this.handleClose}
								aria-labelledby="simple-modal-title"
								aria-describedby="simple-modal-description"
								fullWidth={true}
								maxWidth={'lg'}
							>
								<div>
									<Grid container item direction="column" alignContent='center'>
										<Grid item alignContent='flex-start'>
											<Button onClick={this.handleClose}>X</Button>
										</Grid>
										<Grid item alignContent='center'>
											<Iframe url={this.state.portalUrl}
												width="1024px"
												height="720px"
												id="TelematicsPortal"
												sandbox={'allow-popups allow-scripts allow-popups-to-escape-sandbox allow-top-navigation allow-same-origin' as any}
											/>
										</Grid>
									</Grid>
								</div>
							</Dialog>
						</Grid>
						<Grid item>
							<Button variant="outlined" color="primary" onClick={this.handleInvitation}>Email Invitation</Button>
							<InvitationDialog
								open={this.state.inviteDialogOpen}
								handleClose={this.handleClose}
								handleInvite={this.sendInvite} />
						</Grid>
						<Grid item>
							<TelematicsScanButton isScanning={isScanning} handleScan={this.handleScan} />
						</Grid>
					</Grid>
					{ /** Display any results if they are available and haven't yet been viewed */}
					<TelematicsResultPopup show={!this.props.applicationState.syncResultViewed
						&& !this.props.applicationState.isCurrentlyScanning
						&& !!this.props.applicationState.syncResult
						&& !!this.props.applicationState.selectedCustomer
						&& this.props.applicationState.syncResult.AccountId === this.props.applicationState.selectedCustomer?.Id}
					newFiles={this.props.applicationState.syncResult?.NewFileCount || 0}
					newNodes={this.props.applicationState.syncResult?.NewNodeCount || 0}
					onClose={() => this.props.applicationState.syncResultViewed = true} />
				</Grid>
			);
		}}</Observer>;
	}
}


