import { observable, computed } from 'mobx';
import { IAccountDTO } from '../Models/IAccountDTO';
import { DataStore } from './DataStore';
import { IJobDTO } from '../Models/IJobDTO';
import { CancellationToken } from '../Threading/CancellationToken';
import { IScanStatusDTO } from '../Models/IScanStatusDTO';

export enum JobSortMethod
{
	Any = 0,
	ByName,
	ByUpdated,
	ByCreated,
	ByStatus
}

/**
 * This store handles application view state; that is, state that we would like to be persisted, but
 * is not business critical and thus not stored to the server.
 * 
 * This allows us to store cross-cutting concerns like 'which customer should be displayed' in 
 * unrelated components.
 */
export class ApplicationState
{
	private readonly _dataStore: DataStore
	public constructor(dataStore: DataStore)
	{ 
		this._dataStore = dataStore;
	}

	/**
	 * Select customer and indicate it should be displayed
	 */
	public selectCustomer(id: string | null): IAccountDTO | null
	{
		this.StopPollingForJobStatuses();
		this.StopTelematicsScanPolling();
		this.ClearTelematicsScanResults();

		if (id == null)
		{
			this.selectedCustomer = null;
		}
		else
		{
			// Find the customer this id references
			const customer = this._dataStore.session?.Accounts.find(c => c.Id == id) || null;
			this.selectedCustomer = customer;

			this._dataStore.ClearJobs();
			// Now get the jobs for this customer
			if(customer)
			{
				this._dataStore.GetJobs(customer.Id, this.includeCompletedJobs, this.includeCancelledJobs);
				this.StartPollingForJobStatuses();
				this.StartTelematicsScanPolling();
			}
		}

		return this.selectedCustomer;
	}

	/**
	 * The currently selected customer.
	 */
	@observable
	public selectedCustomer: IAccountDTO | null = null;

	public selectJob(jobId: string | null) : IJobDTO | null
	{
		if(jobId == null)
		{
			this.selectedJob = null;
		}
		else
		{
			const job = this._dataStore.jobs.find(j => j.Id == jobId) || null;
			this.selectedJob = job;
		}

		return this.selectedJob;
	}

	@observable
	public selectedJob: IJobDTO | null = null;

	@observable
	public jobSortMethod: JobSortMethod = JobSortMethod.ByCreated;

	@observable
	public jobSortAscending: boolean = true;

	private applySorting()
	{
		switch (this.jobSortMethod)
		{
		case JobSortMethod.Any:
			return this._dataStore.jobs.slice();
		case JobSortMethod.ByCreated:
			return this._dataStore.jobs.slice().sort((a, b) => b.Created.localeCompare(a.Created));
		case JobSortMethod.ByUpdated:
			return this._dataStore.jobs.slice().sort((a, b) => b.LastUpdated.localeCompare(a.LastUpdated));
		case JobSortMethod.ByName:
			return this._dataStore.jobs.slice().sort((a, b) => a.File.ObjectKey.toLocaleLowerCase().localeCompare(b.File.ObjectKey.toLocaleLowerCase()));
		case JobSortMethod.ByStatus:
			return this._dataStore.jobs.slice().sort((a, b) => a.CurrentStatus.toLocaleLowerCase().localeCompare(b.CurrentStatus.toLocaleLowerCase()));
		}
	}

	@computed
	public get sortedJobs()
	{
		if(!this._dataStore.jobs)
		{
			return [];
		}

		const sorted = this.applySorting();
		if(!this.jobSortAscending)
		{
			return sorted.reverse();
		}
		return sorted;
	}

	@observable
	public includeCompletedJobs: boolean = false;

	@observable
	public includeCancelledJobs: boolean = false;

	public StartPollingForJobStatuses()
	{
		this.timeoutId = setTimeout(() => this.PollForJobStatuses(), this.pollFrequency);
	}

	public StopPollingForJobStatuses()
	{
		if(this.cancellationToken)
		{
			this.cancellationToken.cancel();
		}

		clearTimeout(this.timeoutId);
		this.timeoutId = null;
	}

	private readonly pollFrequency = 30000;
	private timeoutId: any = null;
	private cancellationToken: CancellationToken | null = null;

	private async PollForJobStatuses()
	{
		if(!this._dataStore.session)
		{
			return;
		}

		if(!this.selectedCustomer)
		{
			return;
		}

		const cancellationToken = new CancellationToken();
		this.cancellationToken = cancellationToken;
		await this._dataStore.GetJobs(this.selectedCustomer.Id, this.includeCompletedJobs, this.includeCancelledJobs, cancellationToken);

		if(cancellationToken.cancelled)
		{
			return;
		}

		this.timeoutId = setTimeout(() => this.PollForJobStatuses(), this.pollFrequency);
	}


	/// region: Telematics Scan state
	private syncTimerId: any | null = null;
	
	@observable
	public syncResult: IScanStatusDTO | null = null;
	
	@observable
	public syncResultViewed : boolean = true;

	@observable
	public isCurrentlyScanning: boolean = false;

	private QueryTelematicsScanStatus = async () =>
	{
		if(!this.selectedCustomer)
		{
			return false;
		}

		const status = await this._dataStore.QueryScanStatus(this.selectedCustomer.Id);
		
		if(!this.selectedCustomer)
		{
			return false;
		}

		if(this.selectedCustomer.Id !== status?.AccountId)
		{
			return false;
		}

		this.syncResult = status;
		this.isCurrentlyScanning = status?.IsScanning || false;
		if(status?.IsScanning)
		{
			this.syncResultViewed = false;
		}
		return this.isCurrentlyScanning;
	}

	public async InitiateScan()
	{
		if(!this.selectedCustomer)
		{
			return false;
		}

		this.syncResult = null;
		this.syncResultViewed = false;
		this.isCurrentlyScanning = true;
		await this._dataStore.ScanRemote(this.selectedCustomer.Id);
		this.StartTelematicsScanPolling();
	}

	public async StartTelematicsScanPolling()
	{
		const isScanning = await this.QueryTelematicsScanStatus();
		if(isScanning)
		{
			this.syncTimerId = setTimeout(() => 
			{
				this.StartTelematicsScanPolling();
			}, 3333);
		}
		else
		{
			this.StopTelematicsScanPolling();
			// after polling is complete, we should fetch jobs
			if(this.selectedCustomer)
			{
				await this._dataStore.GetJobs(this.selectedCustomer.Id, this.includeCompletedJobs, this.includeCancelledJobs);
			}
		}
	}

	public StopTelematicsScanPolling()
	{
		if(this.syncTimerId)
		{
			clearTimeout(this.syncTimerId);
			this.syncTimerId = null;
		}
	}

	public ClearTelematicsScanResults()
	{
		this.syncResult = null;
		this.syncResultViewed = true;
		this.isCurrentlyScanning = false;
	}
}

