import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from '@material-ui/core';
import { DropZone } from '../../../Components/Dropzone';
import { IStandardAppProps } from '../../../Models/IStandardAppProps';
import { UploadJobTile } from './UploadJobTile';
import './UploadJob.scss';

export interface IUploadJobProps extends IStandardAppProps
{
	open: boolean;
	handleClose: () => void;
}

export interface IUploadJobState
{
	files: Array<File>;
	uploading: boolean;
	successfullUploaded: boolean;
	uploadProgress: {[key:string]:IFileProgress};
	userMessage: string | null;
	userMessageTitle: string | null;
}

interface IFileProgress
{
	name: string;
	state: 'pending' | 'done' | 'error' | 'cancelled';
	percentage: number;
}

export class UploadJob extends React.PureComponent<IUploadJobProps, IUploadJobState>
{
	public constructor(props: IUploadJobProps)
	{
		super(props);
		this.state = {
			files: [],
			uploading: false,
			successfullUploaded: false,
			uploadProgress:{},
			userMessage: null,
			userMessageTitle: null
		};
	}

	private onFilesAdded = (files:Array<File>) =>
	{
		const filesCopy = this.state.files.slice();
		filesCopy.push(...files);
		this.setState({files:filesCopy});
	}

	private onFileRemoved = (filename: string)=>
	{
		const file = this.state.files.find(file => file.name === filename);
		if(file)
		{
			// if we are uploading this file, it needs to be cancelled
			if(this.state.uploading)
			{
				this.props.dataStore.cancelFileUpload(file.name);
			}

			// in either case, we need to remove it from the list of files to be uploaded
			const fileIdx = this.state.files.indexOf(file);
			const filesCopy = this.state.files.slice();
			filesCopy.splice(fileIdx,1);
			if(filesCopy.length > 0)
			{
				this.setState({files: filesCopy});
			}
			else
			{
				this.setState({ successfullUploaded: true, files: [], uploading: false});
			}
		}

	}

	private uploadFiles = async () =>
	{
		this.setState({ uploadProgress: {}, uploading: true });
		const promises = this.state.files.map(file => this.sendRequest(file));

		try
		{
			await Promise.all(promises);
			this.setState({ successfullUploaded: true, uploading: false }, () => this.props.handleClose());
		}
		catch (e)
		{
			const erroredFiles = Object.values(this.state.uploadProgress).filter(v => v.state === 'error').map(v => '  ' + v.name);
			const cancelledFiles = Object.values(this.state.uploadProgress).filter(v => v.state === 'cancelled').map(v => '  ' + v.name);
			if(erroredFiles.length > 0)
			{
				const errorMessage = 'Some files failed to upload:\n' + erroredFiles.join('\n');
				this.setState({ successfullUploaded: true, uploading: false, userMessageTitle:'Error uploading data', userMessage:errorMessage });
			}
		}
		finally
		{
			this.setState({files: this.state.files.filter(file => this.state.uploadProgress[file.name].state !== 'done')});
		}
	}

	private sendRequest = (file: File) =>
	{
		if(!this.props.applicationState.selectedCustomer)
		{
			throw 'No customer selected.';
		}

		return this.props.dataStore.UploadFile(this.props.applicationState.selectedCustomer.Id,
			file,
			(filename: string, percentage: number) =>
			{
				const copy = { ...this.state.uploadProgress };
				copy[filename] = {
					name: filename,
					state: 'pending',
					percentage: percentage
				};
				this.setState({ uploadProgress: copy });
			},
			(filename: string) =>
			{
				const copy = { ...this.state.uploadProgress };
				copy[filename] = { name: filename, state: 'done', percentage: 100 };
				this.setState({ uploadProgress: copy });
			},
			(filename: string) =>
			{
				const copy = { ...this.state.uploadProgress };
				copy[filename] = { name: filename, state: 'cancelled', percentage: 0 };
				this.setState({ uploadProgress: copy });
			},
			(filename: string) =>
			{
				const copy = { ...this.state.uploadProgress };
				copy[filename] = { name: filename, state: 'error', percentage: 0 };
				this.setState({ uploadProgress: copy });
			}
		);
	}

	private handleClose = () =>
	{
		if(this.state.uploading)
		{
			return;
		}

		this.setState({successfullUploaded:false, uploading:false, uploadProgress:{}, files:[]});
		this.props.handleClose();
	}

	private handleCancelUploads = () =>
	{
		this.props.dataStore.cancelAllFileUploads();
		this.setState({successfullUploaded:true, uploading:false, files:[]});
	}

	private handleCancel = () =>
	{
		this.handleCancelUploads();
		this.handleClose();
	}

	public render()
	{
		return <Dialog className="UploadJobDialog" open={this.props.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">File List</DialogTitle>
			<DialogContent>
				{
					this.state.uploading ? <></> : <DropZone disabled={false} onFilesAdded={this.onFilesAdded} title="Browse for Files" />
				}
				<div className="Files">
					{
						this.state.files.map(file => (
							<UploadJobTile key={file.name} filename={file.name} uploading={this.state.uploading} onFileRemoved={this.onFileRemoved} uploadProgress={this.state.uploadProgress[file.name]?.percentage} />
						))
					}
				</div>
			</DialogContent>
			<DialogActions>
				{
					this.state.uploading ? (
						<>
							<div className="IndeterminateProgressContainer">
								<CircularProgress />
							</div>
							<Button onClick={this.handleCancelUploads} color="primary">
								Cancel
							</Button>
						</>
					):(
						<>
							<Button onClick={this.uploadFiles} color="primary">
								Upload
							</Button>
							<Button onClick={this.handleCancel} color="primary">
								Cancel
							</Button>
						</>
					)
				}
			</DialogActions>
			<Dialog
				open={!!this.state.userMessage}
				onClose={() => this.setState({userMessage:null})}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{this.state.userMessageTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<pre>{this.state.userMessage}</pre>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.setState({userMessage:null})} color="primary" autoFocus>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</Dialog>;
	}
}
