import React from 'react';
import { Paper, FormGroup, TextField, Button, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import './Mock.scss';
import { IMessageResponse } from '../Models/IMessageResponse';
import { DataStore } from '../Stores/DataStore';

interface IMockLauncherState
{
	id: string;
	name: string;

	customers: Array<{ id: string; name: string }>;

	newId: string;
	newName: string;

	regUserId: string;
	regCustomerId: string;

	currentTab: number;
}

/**
 * This exposes a mock launcher that can create a valid signed token (that only works on test) to
 * kick start a session with. 
 */
export class MockLauncher extends React.PureComponent<{dataStore:DataStore}, IMockLauncherState>
{
	public constructor(props: {dataStore:DataStore})
	{
		super(props);

		// Assign our initial state (mad up data)
		this.state = {
			id: 'Agent1',
			name: 'Test Agent',
			customers: [
				{ id: 'Customer1', name: 'Alan Alanson' },
				{ id: 'Customer2', name: 'Bob Bobbinson' },
				{ id: 'Customer3', name: 'Cecil Chantilly' },
				{ id: 'Customer4', name: 'Erica Ericson' },
				{ id: 'Customer5', name: 'Felicia Ferris' },
				{ id: 'Customer6', name: 'Gloria Gruff' },
				{ id: 'Customer7', name: 'Haden Harris' }
			],

			newId: '',
			newName: '',

			regUserId: 'Customer1',
			regCustomerId: 'Agent1',

			currentTab: 0
		};
	}

	public render()
	{
		return (
			<div>
				<AppBar position="static">
					<Tabs value={this.state.currentTab} onChange={(event: React.ChangeEvent<{}>, newValue: number) =>
					{
						this.setState({ currentTab: newValue });
					}}>
						<Tab label="Register a user" />
						<Tab label="Launch Portal" />
						<Tab label="Add a Grower" />
					</Tabs>
				</AppBar>
				{this.state.currentTab == 1 ? <>
					{/** Provide some helper text for our testers */}
					<Paper className="FieldGroup">
						<Typography variant="body1">
							This is a mock page that you can create a validated token which it then uses to get you into the real page.
							So you add an agent id and name (that&apos;s the user) and a list of customers.  Hit submit and it creates the same token that RCIS will (but with a different public/private key).
							Then it launches a session with that token.
						</Typography>
					</Paper>
					{/** Define the agent data */}
					<Paper className="FieldGroup ExistingContentPanel">
						<TextField label="Agent Id" defaultValue={this.state.id} onChange={(e) => this.setState({ id: e.target.value })} />
						<TextField label="Agent Name" defaultValue={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
					</Paper>
					{/** Present an array of customers */}
					<Paper className="FieldGroup">
						{
							this.state.customers.map((c, idx) => (
								<Paper className="ExistingContentPanel" key={idx}>
									<TextField label={`Account ${idx} Id`} defaultValue={this.state.customers[idx].id}
										onChange={(e) =>
										{
											const customers = this.state.customers.slice();
											customers[idx].id = e.target.value;
											this.setState({ customers: customers });
										}}
									/>
									<TextField label={`Account ${idx} Name`} defaultValue={this.state.customers[idx].name}
										onChange={(e) =>
										{
											const customers = this.state.customers.slice();
											customers[idx].name = e.target.value;
											this.setState({ customers: customers });
										}}
									/>
								</Paper>
							))
						}
					</Paper>
					{/** Present an empty customer that can be added to the array */}
					<Paper className="FieldGroup NewCustomerPanel">
						<TextField label="New Customer Id" defaultValue={this.state.newId} onChange={(e) => this.setState({ newId: e.target.value })} />
						<TextField label="New Customer Name" defaultValue={this.state.newName} onChange={(e) => this.setState({ newName: e.target.value })} />
						<Button onClick={(e) =>
						{
							const customers = this.state.customers.slice();
							customers.push({ id: this.state.newId, name: this.state.newName });
							this.setState({ customers: customers });
						}}>Add</Button>
					</Paper>
					{/** 
					 * Finally the submit button, which will transmit the configured data from above to the server,
					 * get a signed token, and use that to start a session.
					 */}
					<Paper className="FieldGroup NewCustomerPanel">
						<Button variant="contained" onClick={async () =>
						{
							// first test sign this
							const signingFetch = await fetch('/api/1/test/sign/agent' +
								`?id=${encodeURIComponent(this.state.id)}` +
								`&name=${encodeURIComponent(this.state.name)}` +
								`&customers=${encodeURIComponent(JSON.stringify(this.state.customers))}`);
							const signingResponse = await signingFetch.json() as IMessageResponse<string>;
							const token = signingResponse.Data;
							// Check for an error
							if (typeof token !== 'string')
							{
								console.error(signingResponse);
								return;
							}
							// Redirect to the session begin endpoint with this token
							window.open(`/api/1/session/agent/${encodeURIComponent(this.state.id)}` +
								`?token=${encodeURIComponent(token)}`);

						}}>Submit to Portal</Button>
					</Paper>
				</> : <></>}
				{this.state.currentTab == 0 ? <>
					<Paper className="FieldGroup NewCustomerPanel">
						<Typography variant="body1">
							The following section gives you the ability to launch a registration event
						</Typography>
						<TextField label="RCIS UserId (actual user logging in to onsite)" defaultValue={this.state.regUserId} onChange={(e) => this.setState({ regUserId: e.target.value })} />
						<TextField label="RCIS CustomerId (Agent if user is grower)" defaultValue={this.state.regCustomerId} onChange={(e) => this.setState({ regCustomerId: e.target.value })} />
						<Button variant="contained" onClick={async () =>
						{
							// first test sign this
							const signingFetch = await fetch('/api/1/test/sign/register' +
								`?userId=${encodeURIComponent(this.state.regUserId)}` +
								`&customerId=${encodeURIComponent(this.state.regCustomerId)}`);

							const signingResponse = await signingFetch.json() as IMessageResponse<string>;
							const token = signingResponse.Data;
							// Check for an error
							if (typeof token !== 'string')
							{
								console.error(signingResponse);
								return;
							}
							// Redirect to the session begin endpoint with this token
							window.open(`${this.props.dataStore.config?.RegistrationPage ?? '<null>'}?Token=${encodeURIComponent(token)}`);

						}}>Submit to Registration</Button>
					</Paper>
				</> : <></>
				}
				{this.state.currentTab == 2 ? <>
					<Paper className="FieldGroup NewCustomerPanel">
						<Typography variant="body1">
							The following section gives you the ability to add a grower
						</Typography>

						<TextField label="RCIS AgentId" defaultValue={this.state.id} onChange={(e) => this.setState({ id: e.target.value })} />
						<TextField label="RCIS GrowerId" defaultValue={this.state.regCustomerId} onChange={(e) => this.setState({ regCustomerId: e.target.value })} />
						<TextField label="RCIS Grower Name" defaultValue={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />

						<Button variant="contained" onClick={async () =>
						{
							// first test sign this
							const signingFetch = await fetch('/api/1/test/sign/add' +
								`?agentId=${encodeURIComponent(this.state.id)}` +
								`&accountId=${encodeURIComponent(this.state.regCustomerId)}` +
								`&agentName=${encodeURIComponent(this.state.name)}`);

							const signingResponse = await signingFetch.json() as IMessageResponse<string>;
							const token = signingResponse.Data;
							// Check for an error
							if (typeof token !== 'string')
							{
								console.error(signingResponse);
								return;
							}

							// Redirect to the session begin endpoint with this token
							window.open(`/api/1/session/${encodeURIComponent(this.state.id)}/add` +
								`?token=${encodeURIComponent(token)}`);

						}}>Submit to Portal</Button>
					</Paper>
				</>:<></>
				}
			</div>
		);
	}
}
