import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

interface ITelematicsScanButtonProps
{
	handleScan: () => void;
	isScanning: boolean;
}

/** This element wraps the button for scanning */
export const TelematicsScanButton = ({ handleScan, isScanning } : ITelematicsScanButtonProps) => 
	<div className="ScanButtonContainer" style={{ position: 'relative' }}>
		<Button variant="outlined" color="primary" disabled={isScanning} onClick={handleScan}>Scan</Button>
		{
			isScanning ?
				<div className="ScanLoader">
					<CircularProgress size={32} />
				</div>
				: <></>
		}
	</div>;
