
export class CancellationToken
{
	public cancel()
	{
		this._cancel = true;
	}

	public get cancelled() : boolean
	{
		return this._cancel;
	}

	private _cancel: boolean = false;
}
