import React, { useRef } from 'react';
import { FileCopyTwoTone } from '@material-ui/icons';
import { Paper, Button } from '@material-ui/core';
import './Dropzone.scss';

export interface IDropZoneProps
{
	onFilesAdded: (files: Array<File>) => void;
	disabled: boolean;
	title: string;
}

export interface IDropZoneState
{
	highlighted: boolean;
}

export class DropZone extends React.PureComponent<IDropZoneProps, IDropZoneState>
{
	private fileInputRef = React.createRef<HTMLInputElement>();
	public constructor(props: IDropZoneProps)
	{
		super(props);
		this.state = {
			highlighted: false
		};
	}

	private fileListToArray = (list: FileList) =>
	{
		const array: Array<File> = [];
		for (var i = 0; i < list.length; i++)
		{
			const file = list.item(i);
			if (file)
			{
				array.push(file);
			}
		}
		return array;
	}

	private onFilesAdded = (evt: React.ChangeEvent<HTMLInputElement>) =>
	{
		if (this.props.disabled)
		{
			return;
		}

		const files = evt.target.files;
		if (this.props.onFilesAdded && files)
		{
			const array = this.fileListToArray(files);
			this.props.onFilesAdded(array);
		}
	}

	private openFileDialog = () =>
	{
		if (this.props.disabled
			|| !this.fileInputRef
			|| !this.fileInputRef.current)
		{
			return;
		}

		this.fileInputRef.current.click();
	}

	private onDragOver = (evt:React.DragEvent) =>
	{
		evt.preventDefault();

		if (this.props.disabled)
		{
			return;
		}

		this.setState({ highlighted: true });
	}

	private onDragLeave = () =>
	{
		this.setState({ highlighted: false });
	}

	private onDrop = (event:React.DragEvent) =>
	{
		event.preventDefault();

		if (this.props.disabled) return;

		const files = event.dataTransfer.files;
		if (this.props.onFilesAdded)
		{
			const array = this.fileListToArray(files);
			this.props.onFilesAdded(array);
		}
		this.setState({ highlighted: false });
	}

	public render()
	{
		return (
			<Button
				variant='outlined'
				className={`Dropzone ${this.state.highlighted ? 'Highlight' : ''}`}
				style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
				onClick={this.openFileDialog}
				onDragOver={this.onDragOver}
				onDragLeave={this.onDragLeave}
				onDrop={this.onDrop}
			>
				<FileCopyTwoTone />
				<input
					ref={this.fileInputRef}
					className="FileInput"
					type="file"
					multiple
					onChange={this.onFilesAdded}
				/>
				<span>{this.props.title}</span>
			</Button>
		);
	}
}